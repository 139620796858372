@import "src/theme/mixins/utils";
@import "src/theme/mixins/landlord-landing";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ion-color-dark-tint);
  @include pxToRem(padding-top, 12);
  @include pxToRem(padding-bottom, 12);
  @include full-width-background(dark);

  .logo {
    @include for-max-size(md) {
      margin: auto;
    }
  }


  .appLogo {
    background-repeat: no-repeat;
    @include pxToRem(margin-top, 4);

    @include pxToRem(height, 26);
    @include pxToRem(width, 100);

    @include for-min-size(md) {
      @include pxToRem(width, 203);
      @include pxToRem(margin-top, 13);
      @include pxToRem(margin-bottom, 13);
    }
  }

  .nav {
    display: flex;
    @include pxToRem(gap, 10);
  }
}
