.dateInputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .dateInput {
    text-align: center;
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);

    &:focus {
      border-color: var(--ion-color-primary);
    }

    &.day {
      width: 100px;
    }

    &.month {
      flex: 1;
    }

    &.year {
      flex: 1;
    }
  }
}
