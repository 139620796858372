@import "src/theme/mixins/utils";

.outer {
  @include pxToRem(padding-top, 40);
  @include pxToRem(margin-bottom, 40);

  &.small {
    @include pxToRem(padding-top, 15);
    @include pxToRem(margin-bottom, 15);
  }

  .wrapper {
    position: relative;
    width: 100%;
    background-color: var(--wohnsinn-border-color);
    @include pxToRem(height, 1);

    .text {
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--ion-background-color);
      @include pxToRem(padding, 10);

    }
  }
}
