@import "src/theme/mixins/utils";

.suggestionsTitle {
  @include pxToRem(margin-bottom, 24);
}


.counter {
  display: flex;
  justify-content: space-between;
  @include pxToRem(margin-top, 24);

  .counterButtonWrapper {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 16);
  }
}

.petsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include pxToRem(padding-right, 24);
}
