@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  width: 100%;
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(padding-bottom, 70);
  @include pxToRem(max-width, 600);


  background-color: var(--ion-background-color);
  border-radius: 10px;
  padding: 1.5rem;


  .logoWrapper {
    @include pxToRem(margin-top, 16);
    @include pxToRem(margin-bottom, 16);

    @include for-min-size(lg) {
      display: none;
    }

    .logo {
      margin-left: auto;
      margin-right: auto;
      @include pxToRem(padding-top, 16);
      @include pxToRem(padding-bottom, 16);
      @include pxToRem(max-width, 150);
    }
  }

  .ctaButtonWrapper {
    background-color: var(--ion-background-color);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include pxToRem(padding-top, 5);
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-right, 16);
    @include pxToRem(max-width, 600);
  }
}
