@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/landlord-landing";

.wrapper {
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include landlord-landing-section-padding;

  .blob {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include pxToRem(gap, 32);
    @include pxToRem(padding-top, 32);
    @include pxToRem(padding-bottom, 16);
  }
}
