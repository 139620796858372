@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.info {
  overflow: hidden;
  width: 100%;

  @include for-min-size(md) {
    @include pxToRem(width, 500);
  }

  .ratings {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: var(--z-index-fixed);

    @include for-min-size(md) {
      @include pxToRem(width, 500);
    }

  }
}

.wrapper {
  overflow: hidden;

  .bookmarkInfo {
    display: none;

    @include for-min-size(md) {
      display: block;
    }

    .backdrop {
      z-index: var(--z-index-modal-backdrop);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgb(0 0 0 / 60%);
    }

    .info {
      z-index: var(--z-index-modal);
      position: absolute;
      border-radius: var(--wohnsinn-border-radius);
      background-color: var(--ion-background-color);
      @include pxToRem(top, 16);
      @include pxToRem(right, 16);
      @include pxToRem(padding, 16);
    }

    .arrow {
      position: absolute;
      z-index: var(--z-index-modal);
      background-color: var(--ion-background-color);
      transform: rotate(-45deg);
      @include pxToRem(width, 20);
      @include pxToRem(height, 20);
      @include pxToRem(top, 11);
      @include pxToRem(right, 32);
    }
  }
}

.map {
  display: none;

  @include for-min-size(md) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    @include pxToRem(left, 500);

    right: 0;
  }
}
