@import "src/theme/mixins/utils";

.wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include pxToRem(right, 16);

  .icon {
    color: var(--ion-text-color-accent);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    @include pxToRem(right, 16);
  }
}
