// THIS PREVENTS TABBING TO NEXT SLIDE (E.G. IN FORMS)
#lightshow-portal {
  .swiper-slide.swiper-slide-active {
    visibility: visible;
  }


  .lightshow-thumbnail-slider {
    .swiper-slide {
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .swiper .swiper-slide {
    text-align: left !important;
  }
}

