@import "src/theme/mixins/utils";

@mixin badgeStyle {
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  background-color: var(--ion-color-danger);
}

.dropdownMenu {
  position: relative;

  .notification {
    position: absolute;
    right: 10px;
    top: 10px;
    @include badgeStyle;
  }

  .avatar {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--wohnsinn-border-color);
    transition: all .2s;
    @include pxToRem(padding, 3);
    @include pxToRem(padding-right, 15);

    &:hover {
      @include boxShadow;
    }

    @include pxToRem(gap, 8);
    @include pxToRem(border-radius, 50);
  }


  .listWrapper {
    display: none;
    position: absolute;
    right: 0;
    z-index: var(--z-index-dropdown);
    @include pxToRem(padding-top, 12);

    .list {
      background-color: var(--ion-background-color);
      border-radius: var(--wohnsinn-border-radius);
      border: 1px solid var(--wohnsinn-border-color);
      overflow: hidden;
      @include boxShadow;
      @include pxToRem(width, 300);
      @include pxToRem(padding-top, 12);
      @include pxToRem(padding-bottom, 12);
    }
  }

  svg {
    color: var(--ion-text-color);
  }

  &:hover {
    .listWrapper {
      display: block;
    }
  }
}

.userName {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding-left, 20);
  @include pxToRem(padding-right, 20);
  @include pxToRem(padding-bottom, 12);
}

.logoutButton {
  width: 100%;
  text-align: left;
}

.logoutButton,
.listItemLink {
  display: block;
  position: relative;
  cursor: pointer;
  transition: background-color .4s;
  border-bottom: 1px solid var(--ion-background-color);
  @include pxToRem(padding-left, 20);
  @include pxToRem(padding-right, 20);
  @include pxToRem(padding-bottom, 10);
  @include pxToRem(padding-top, 10);

  &.withNotification {
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      z-index: 99999;
      content: '';
      @include badgeStyle;
    }
  }



  svg, span {
    transition: color .4s;
  }

  &:hover {
    background-color: var(--ion-color-light);
    text-decoration: none;

    svg,
    span {
      color: var(--ion-color-primary);
    }
  }
}
