@use "sass:map";

@mixin pxToRem($property, $value) {
  #{$property}: calc($value / 16) + rem;
}

@mixin disabled {
  cursor: auto;
  opacity: .5;
  pointer-events: none;
}

@mixin boxShadow($light: false) {
  box-shadow: rgb(99 99 99 / 30%) 0 2px 8px 0;

  @if $light {
    box-shadow: rgb(99 99 99 / 15%) 0 2px 8px 0;
  }
}

@mixin buttonAnimation {
  transition: transform .3s;
  box-shadow: none;

  &:hover {
    transform: scale(1.03);
    @include boxShadow;
  }

}

@mixin hideScrollBars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin loadingSkeleton {
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200deg 20% 80%);
    }

    100% {
      background-color: hsl(200deg 20% 95%);
    }
  }
}


@mixin full-width-background($color) {
  $colors: (
          primary: var(--ion-color-primary),
          dark: var(--ion-color-dark-tint),
          tertiary: var(--ion-color-tertiary)
  );
  $newColor: map.get($colors, $color);

  box-shadow: 0 0 0 100vmax $newColor;
  clip-path: inset(0 -100vmax);
}


