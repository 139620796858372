@import "src/theme/mixins/utils";

.wrapper {
  display: block;
  justify-content: center;
  width: 100%;

  @include pxToRem(max-width, 120);

  &.active {
    svg {
      color: var(--ion-color-primary);
    }
  }
}

.tabBarItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--ion-text-color);
  @include pxToRem(gap, 4);
  @include pxToRem(margin-top, 8);
  @include pxToRem(margin-bottom, 8);

  .label {
    @include pxToRem(font-size, 11);
  }

  .badgeWrapper {
    position: absolute;
    top: 0;
    left: 50%;
  }
}
