@import "src/theme/mixins/utils";

.ctaCard {
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include pxToRem(gap, 32);

}

.image {
  @include pxToRem(max-width, 200);
}


.ctaWrapper {
  width: 100%;
}
