@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(padding-bottom, 16);

.suggestionsTitle {
  @include pxToRem(margin-top, 24);
}

.citySuggestions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include pxToRem(gap, 10);
  @include pxToRem(margin-top, 16);
  @include pxToRem(margin-bottom, 16);

  .citySuggestionBtn {
    border-radius: var(--wohnsinn-border-radius);
    border: 1px solid var(--wohnsinn-border-color);

    &:hover {
      background-color: var(--ion-color-tertiary);
    }

    &:active {
      background-color: var(--ion-color-primary);
    }
  }
}
}
