@import "src/theme/mixins/responsive";
@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.modalWrapper {
  overflow-y: scroll;
  position: relative;
  height: 100%;
  @include pxToRem(padding-top, 16);

  @include for-min-size(md) {
    max-height: 90vh;
    height: 100%;
    @include pxToRem(padding-bottom, 32);
  }
}

.modalHeader {
  text-align: center;
  @include pxToRem(margin-bottom, 15);

  @include for-min-size(lg) {
    @include pxToRem(margin-bottom, 27);
  }

  &.noHeadingMargin {
    margin-bottom: 0;
  }

  .icon {
    margin: 0 auto;
    @include pxToRem(margin-bottom, 16);
    @include pxToRem(max-height, 30);
    @include pxToRem(max-width, 120);
  }

  .iconWrapper {
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @include pxToRem(margin-bottom, 16);
    @include pxToRem(height, 48);
    @include pxToRem(width, 48);

    .icon {
      color: var(--ion-text-color);
      margin: 0;
    }
  }

  .button {
    margin: 0 0 1rem 2rem;
    color: var(--ion-text-color);
    position: absolute;
    @include pxToRem(top, 12);
  }

  .close {
    position: absolute;
    @include pxToRem(right, 12);

    .closeIcon {
      @include pxToRem(font-size, 28);
    }
  }

  .back {
    @include pxToRem(left, 12);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include pxToRem(padding-left, 6);
  @include pxToRem(padding-right, 6);
}

.childrenWrapper {
  height: 100%;
  max-height: calc(100% - 45px);
  color: var(--ion-text-color);

  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    @include pxToRem(padding-left, 32);
    @include pxToRem(padding-right, 32);
  }

  &.noContentPadding {
    padding-left: 0;
    padding-right: 0;
  }
}
