@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.navigation {
  background-color: var(--ion-color-dark-tint);
  @include full-width-background(dark);

  @include for-min-size(md) {
    display: block;
  }

  .navigationContent {
    display: flex;
    flex-direction: column;

    .logo {
      @include for-max-size(md) {
        margin: auto;
      }
    }

    .links {
      display: none;
      @include for-min-size(md) {
        display: block;
      }
    }

    @include for-min-size(md) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }


    .appLogo {
      background-repeat: no-repeat;
      @include pxToRem(margin-top, 4);

      @include pxToRem(height, 26);
      @include pxToRem(width, 100);

      @include for-min-size(md) {
        @include pxToRem(width, 203);
        @include pxToRem(margin-top, 13);
        @include pxToRem(margin-bottom, 13);
      }
    }


    .buttonWrapper {
      display: none;
      @include pxToRem(margin-left, 12);

      @include for-min-size(md) {
        display: flex;
        @include pxToRem(gap, 10);
      }
    }

  }
}
