@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";

.map {
  position: relative;
  width: 100%;

  @include for-min-size(lg) {
    display: block;
  }
}

.titleWrapper {
  @include pxToRem(margin-top, 32);
}

.spacer {
  @include pxToRem(height, 32);
}

.listWrapper {
  @include pxToRem(padding, 16);

  .segmentIcon {
    display: inline-block;
    @include pxToRem(margin-left, 6);
    @include pxToRem(width, 20);
  }
}

.list {
  @include pxToRem(margin-top, 8);
}

.listItem {
  @include pxToRem(margin-bottom, 8);
}

.apartmentInformationWrapper {
  background-color: var(--ion-background-color);
  @include pxToRem(padding-top, 16);
}
