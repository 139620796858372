@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

ion-modal.modal {
  @include pxToRem(padding-top, 10);

  --background: var(--ion-background-color);
  --border-radius: 10px 10px 0 0;

  @include for-min-size(md) {
    padding-top: 0;

    --border-radius: 10px;
    --height: auto;
  }

  @include for-max-size(md) {
    --width: 100%;
  }

}

