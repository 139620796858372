@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";

.informationWrapper {
  width: 100%;
  @include pxToRem(padding-bottom, 8);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(padding-top, 16);

  @include for-min-size(md) {
    padding-top: 0;
    @include pxToRem(padding-bottom, 100);
  }

  &.isUnpublished {
    opacity: .6;
    pointer-events: none;
    cursor: pointer;
  }

}

.apartmentImage {
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;

  .placeHolder {
    height: 300px;
    background-position: center;
    background-size: cover;
  }
}

.mapPlaceHolder {
  background: linear-gradient(0deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), url("../../../../public/assets/images/map-placeholder.png") center;
  background-size: cover;
  width: 100%;
  @include pxToRem(height, 250);
}

.mapCloseButton {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--ion-color-light);;
}

.closeMapButton {
  position: absolute;
  z-index: var(--z-index-base);
  @include pxToRem(left, 4);
  @include pxToRem(top, 4);
}

.openMapButton {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-base);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: rgb(0 0 0 / 40%);
  }
}

.mapPreview {
  position: relative;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;

  @include for-min-size(md) {
    display: none;
  }
}

.apartmentCard {
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 12px;

  .disabledTagWrapper {
    position: absolute;
    z-index: var(--z-index-dropdown);
    @include pxToRem(top, 16);
    @include pxToRem(left, 16);
  }
}

.informationSection {
  display: flex;
  flex-direction: column;
  @include pxToRem(margin-top, 50);
  @include pxToRem(gap, 16);

}
