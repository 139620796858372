@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  align-items: center;

  > ion-router-link {
    @include pxToRem(margin-left, -9)
  }

  @include for-max-size(md) {
    display: none;
  }

  ion-breadcrumb {
    &::part(native) {
      padding: 0;
    }
  }
}
