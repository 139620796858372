@import "../../../theme/mixins/fonts";
@import "../../../theme/mixins/utils";
@import "../../../theme/mixins/responsive";

.wrapper {
  background: var(--ion-background-color);
  box-shadow: 0 -2px 10px -3px rgb(0 0 0 / 30%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--wohnsinn-border-radius) var(--wohnsinn-border-radius) 0 0;
  @include pxToRem(gap, 32);
  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-bottom, 10);

  @include for-min-size(md) {
    box-shadow: none;
    border-radius: 0;
    border-right: 1px solid var(--wohnsinn-border-color);
    @include pxToRem(margin-right, 0);
    @include pxToRem(margin-left, 0);
  }

  .buttonAnimation {
    @include buttonAnimation;
  }

  .singleButton {
    width: 90%;
  }
}
