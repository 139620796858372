@use "sass:map";
@import 'responsive';

// default line-height
$line-height    : 1.25;

// font-sizes
$font-size-xxs   : .55rem;
$font-size-xs   : .75rem;
$font-size-sm   : .875rem;
$font-size-base : 1rem;
$font-size-lg   : 1.125rem;
$font-size-xl   : 1.25rem;
$font-size-1xl  : 1.375rem;
$font-size-2xl  : 1.625rem;
$font-size-3xl  : 2rem;
$font-size-4xl  : 2.4375rem;
$font-size-5xl  : 3rem;

// font-weight
$font-thin      : 100;
$font-light     : 300;
$font-normal    : 500;
$font-bold      : 700;

// font-weight map
$fontWeights: (
        "thin": $font-thin,
        "light": $font-light,
        "normal": $font-normal,
        "bold": $font-bold,
);

@mixin line-height($font-size) {
  line-height: $line-height * $font-size;
}

// text-extra-large (Desktop: 33 Mobil: ?)
@mixin text-extra-large($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-2xl;
  @include line-height($font-size-2xl);

  @include for-min-size(lg) {
    font-size: $font-size-3xl;
    @include line-height($font-size-3xl);
  }
}

// text-large (XD: Desktop: 18 Regular Mobil: 18?)
@mixin text-large($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-lg;
  @include line-height($font-size-lg);

  @include for-min-size(lg) {
    font-size: $font-size-xl;
    @include line-height($font-size-xl);
  }
}

// text-base (XD: Desktop: 16 Regular Mobil: 14)
@mixin text-base($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-base;
  @include line-height($font-size-base);

  @include for-min-size(lg) {
    font-size: $font-size-lg;
    @include line-height($font-size-lg);
  }
}

// text-small (XD: Desktop: 14 Regular Mobil: 12)
@mixin text-small($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-sm;
  @include line-height($font-size-sm);

  @include for-min-size(lg) {
    font-size: $font-size-base;
    @include line-height($font-size-base);
  }
}

// text-tiny (XD: Desktop: 12 Regular Mobil: 10)
@mixin text-tiny($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-xs;
  @include line-height($font-size-xs);

  @include for-min-size(lg) {
    font-size: $font-size-sm;
    @include line-height($font-size-sm);
  }
}

// text-extra-tiny (XD: Desktop: 10 Regular Mobil: 8)
@mixin text-extra-tiny($weight: "normal") {
  font-weight: map.get($fontWeights, $weight);
  font-size: $font-size-xxs;
  @include line-height($font-size-xs);

  @include for-min-size(lg) {
    font-size: $font-size-xs;
    @include line-height($font-size-sm);
  }
}