@import "src/theme/mixins/utils";

.wrapper {
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding-left, 6);
  @include pxToRem(padding-right, 6);
  @include pxToRem(padding-top, 24);
  @include pxToRem(padding-bottom, 32);
  @include pxToRem(margin-bottom, 32);

  .warmRent {
    display: flex;
    align-items: flex-end;
    @include pxToRem(margin-bottom, 10);
    @include pxToRem(gap, 12);

    span:first-of-type {
      line-height: 31px;
    }
  }

  .street {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 8);
    @include pxToRem(margin-top, 24);

    svg {
      color: var(--ion-text-color);
    }
  }
}

.iconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

svg.icon {
  color: var(--ion-color-primary);
  @include pxToRem(margin-right, 6);
}

.infos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px 16px;
  @include pxToRem(margin-top, 24);
}

