@import "src/theme/mixins/utils";

.header {
  display: flex;
  justify-content: flex-end;
  background: var(--ion-color-primary);
  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-bottom, 16);
  @include pxToRem(padding-right, 12);
  @include pxToRem(padding-left, 12);
  @include pxToRem(gap, 12);
}
