.wrapper {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageWrapper {
  img {
    max-width: 200px;
  }
}

.contentWrapper {
  text-align: center;
  margin-top: 1rem;
}