@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";
@import "mapbox";

/* IonRange */
ion-range {
  @include pxToRem(--bar-border-radius, 8);
  @include pxToRem(--bar-height, 8);
  @include pxToRem(--knob-size, 24);
  @include pxToRem(--height, 48);

  --knob-background: var(--ion-color-light);
  --pin-background: var(--ion-color-light);
  --pin-color: var(--ion-color-text);
  --bar-background: var(--wohnsinn-border-color);
  --knob-box-shadow: 0 0 0 2px var(--ion-color-primary);
}

ion-range.range-has-pin {
  padding-top: 3rem;
  padding-left: 0;
  padding-right: 0;
}

ion-range::part(pin) {
  @include pxToRem(padding-top, 12);
  @include pxToRem(padding-bottom, 12);

  display: flex;
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-background-color);
  border: 1px solid var(--wohnsinn-border-color);
  top: -2.5rem;
  min-width: 8.5rem;
  min-height: 2.5rem;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%) scale(1);

  @include for-max-size(md) {
    position: fixed;
  }
}

ion-range::part(tick-active) {
  transform: scale(1.01) translate3d(-34px, 0, 0);
}

ion-range::part(pin)::before {
  display: none;
}

ion-range::part(knob) {
  transform: none;
}

ion-range::part(knob)::before {
  all: unset;
}

ion-item {
  @include pxToRem(margin-bottom, 4);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// hide default checkbox
.styled-checkbox {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  @include pxToRem(height, 1);

  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  @include pxToRem(width, 1);

  &:focus-visible {
    & + .checkbox {
      border-color: black;
    }
  }
}

ion-radio-group {
  ion-label {
    font-size: 14px !important;
  }
}

// Ionic Popover
ion-popover {
  --width: 360px;

  @include for-min-size(md) {
    --width: 400px;
  }

  @include for-min-size(lg) {
    --width: 450px;
  }
}

/* IonAlert */
.alert-wrapper {
  min-width: 80vw !important;
  @include for-min-size(sm) {
    min-width: 22rem !important;
  }
}

.alert-button-role-destructive {
  color: var(--ion-color-danger);
}

.breadcrumb-active {
  color: var(--ion-color-primary);
  font-weight: bold;
}

.input, {
  --background: transparent;

  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);


  &:active,
  &:focus-within,
  &:focus {
    border: 1px solid var(--ion-color-primary);
  }
}

ion-content {
  --background: var(--ion-color-light);
}
