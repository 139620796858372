@import "src/theme/mixins/responsive";

.togglePoiButton {
  position: absolute;
  z-index: var(--z-index-fixed);
  box-shadow: 5px 5px 6px -4px rgb(0 0 0 / 30%);
  bottom: 3rem;
  right: .5rem;

  @include for-min-size(lg) {
    bottom: 2.5rem;
  }

}

.locationMarker {
  font-size: 3rem;
  color: var(--ion-color-primary);
}
