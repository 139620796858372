@import "src/theme/mixins/utils";

.suggestionBtn {
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  width: 100%;

  &:hover {
    background-color: var(--ion-color-tertiary);
  }

  &:active {
    background-color: var(--ion-color-primary);
  }
}
