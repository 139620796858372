@import "../../../../theme/mixins/responsive";
@import "../../../../theme/mixins/fonts";
@import "../../../../theme/mixins/utils";

.tunnelSlide {
  text-align: left;
  background-color: var(--ion-background-color);
  position: relative;
  width: 100%;
  @include text-small;

  @include pxToRem(padding, 16);
  @include pxToRem(padding-bottom, 48);

  @include for-min-size(lg) {
    margin-left: auto;
    margin-right: auto;

    @include pxToRem(border-radius, 10);
    @include pxToRem(padding, 16);
    @include pxToRem(padding-bottom, 90);
    @include pxToRem(margin-top, 100);
    @include pxToRem(width, 600);
    @include pxToRem(min-height, 400);
  }

  &.searchTunnelSlide {
    text-align: center;
  }
}

.backButton {
  color: var(--ion-color-primary);
}

.headline {
  text-align: center;
  @include pxToRem(padding-top, 24);
  @include pxToRem(margin-bottom, 16);
}

.buttonWrapper {
  position: absolute;
  bottom: 0;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 8);

  @include for-min-size(lg) {
    @include pxToRem(bottom, 16);
  }
}

.nextButton {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  @include pxToRem(left, 16);
  @include pxToRem(margin-bottom, 16);
}
