@import "src/theme/mixins/utils";

.badge {
  background-color: var(--ion-color-danger);
  display: flex;
  justify-content: center;
  align-items: center;

  @include pxToRem(border-radius, 10);
  @include pxToRem(padding-left, 5);
  @include pxToRem(padding-right, 5);
  @include pxToRem(height, 25);
  @include pxToRem(min-width, 25);



  &.SMALL {
    @include pxToRem(height, 15);
    @include pxToRem(min-width, 15);
  }

  &.MEDIUM {
    @include pxToRem(height, 17);
    @include pxToRem(min-width, 17);
  }

  &.empty {
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
  }
}
