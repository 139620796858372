@import "src/theme/mixins/utils";

.infoBox {
  background-color: var(--ion-color-tertiary);
  display: flex;
  align-items: center;
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding, 16);
  @include pxToRem(gap, 16);
  @include pxToRem(margin-top, 8);
  @include pxToRem(margin-bottom, 8);

  svg {
    color: var(--ion-color-warning-shade);
  }

  .textWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  &.big {
    margin-top: 0;
    @include pxToRem(padding-top, 32);
    @include pxToRem(padding-bottom, 32);
    @include pxToRem(padding-left, 24);
    @include pxToRem(padding-right, 24);
    @include pxToRem(margin-bottom, 32);

    .textWrapper {
      flex-direction: column;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
