@import "src/theme/mixins/landlord-landing";

.wrapper {
  display: flex;
  @include landlord-landing-section-padding;

  @include for-max-size(lg) {
    @include pxToRem(padding, 24);
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
  }

  .row {
    display: flex;
    @include pxToRem(gap, 50);
    @include pxToRem(margin-top, 20);

    .column {
      display: flex;
      flex-direction: column;
      @include pxToRem(gap, 24);

      @include for-min-size(md) {
        @include pxToRem(gap, 50);
      }
    }
  }

  .heatMap {
    flex: 1;
    display: none;

    img {
      margin-left: auto;
    }

    @include for-min-size(md) {
      display: block;
    }
  }
}
