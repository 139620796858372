@import "src/theme/mixins/utils";

.linkElement {
  --max-height: 40px;
  --min-height: 40px !important;


  ion-label {
    margin-top: 0;
    margin-bottom: 0;
  }

  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  cursor: pointer;

  &:hover {
    --background: var(--ion-color-light-shade);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &.red {
    background-color: var(--ion-color-danger);
  }

  &.blue {
    background-color: var(--ion-color-primary);
  }

  @include pxToRem(width, 25);
  @include pxToRem(height, 25);
  @include pxToRem(border-radius, 50);
}