.backButton {
  width: 30px;
  height: 30px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--ion-color-tertiary);
  }

  svg {
    color: var(--ion-text-color);
  }
}
