.loadingScreen {
  img {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    animation: fadeIn 1s;
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}