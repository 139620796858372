@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.backdrop {
  background-color: rgb(0 0 0 / 10%);
  z-index: var(--z-index-lightshow-backdrop);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include for-min-size(md) {
    display: none;
  }
}

.mobileTabsWrapper {
  position: sticky;
  top: 0;
  z-index: var(--z-index-lightshow);
}

.tabToggleButton {
  background-color: var(--ion-background-color);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(margin-bottom, 15);
  @include pxToRem(padding-top, 8);
  @include pxToRem(padding-bottom, 8);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    display: none;
  }
}

.tabs {
  background-color: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(margin-right, 20);
  @include pxToRem(padding-top, 24);
  @include pxToRem(padding-bottom, 24);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-max-size(md) {
    margin-right: 0;
    @include pxToRem(padding-top, 16);
    @include pxToRem(padding-bottom, 16);
    @include pxToRem(padding-left, 12);
    @include pxToRem(padding-right, 12);
    @include pxToRem(margin-bottom, 20);
  }

  &.hideMobile {
    @include for-max-size(md) {
      display: none;
    }
  }

  &.hideDesktop {
    @include for-min-size(md) {
      display: none;
    }
  }

  &.hide {
    @include for-max-size(md) {
      display: none;
    }
  }

  .tabItem {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: var(--wohnsinn-border-radius);
    @include pxToRem(padding, 4);
    @include pxToRem(margin-bottom, 3);
    @include pxToRem(padding-left, 4);

    @include for-min-size(md) {
      @include pxToRem(padding, 8);
      @include pxToRem(padding-left, 8);
    }

    .status {
      color: var(--ion-color-danger);
    }

    &:hover,
    &.active {
      background-color: var(--ion-color-tertiary);
    }
  }
}

.label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    @include pxToRem(width, 20);
    @include pxToRem(margin-right, 9);
  }
}

.panel {
  background-color: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding-left, 20);
  @include pxToRem(margin-bottom, 16);
  @include pxToRem(padding, 24);

  @include for-max-size(md) {
    border-left: none;
    @include pxToRem(padding, 16);
  }
}
