@mixin hover-zoom {
  transition: transform 0.3s;
  transform: scale(1.02);
}

$jiggleAnimationMovement: .023rem;
$jiggleAnimationMovement2x: $jiggleAnimationMovement * 2;
$jiggleAnimationRotation: .5deg;
@mixin jiggle {
  animation: shake 1.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate($jiggleAnimationMovement, $jiggleAnimationMovement) rotate(0deg); }
  10% { transform: translate(-$jiggleAnimationMovement, -$jiggleAnimationMovement2x) rotate(-$jiggleAnimationRotation); }
  20% { transform: translate(-$jiggleAnimationMovement2x, 0) rotate($jiggleAnimationRotation); }
  30% { transform: translate($jiggleAnimationMovement2x, $jiggleAnimationMovement2x) rotate(0deg); }
  40% { transform: translate($jiggleAnimationMovement, -$jiggleAnimationMovement) rotate($jiggleAnimationRotation); }
  50% { transform: translate(-$jiggleAnimationMovement, $jiggleAnimationMovement2x) rotate(-$jiggleAnimationRotation); }
  60% { transform: translate(-$jiggleAnimationMovement2x, $jiggleAnimationMovement) rotate(0deg); }
  70% { transform: translate($jiggleAnimationMovement2x, $jiggleAnimationMovement) rotate(-$jiggleAnimationRotation); }
  80% { transform: translate(-$jiggleAnimationMovement, -$jiggleAnimationMovement) rotate($jiggleAnimationRotation); }
  90% { transform: translate($jiggleAnimationMovement, $jiggleAnimationMovement2x) rotate(0deg); }
  100% { transform: translate($jiggleAnimationMovement, -$jiggleAnimationMovement2x) rotate(-$jiggleAnimationRotation); }
}
