@import "src/theme/mixins/responsive";
@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.form {
  text-align: left;

  input {
    background-color: var(--ion-background-color);
  }
}

.filterLabel {
  margin-bottom: .5rem;
  display: block;
}

.filterSection {
  margin: 2rem 0;
  z-index: var(--zindex-modal);

  h1 {
    margin-bottom: 18px!important;
  }
}

.handleButton {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1rem 0 2rem;
  z-index: 10;
  background-color: var(--ion-background-color);

  @include for-min-size(md) {
    @include pxToRem(bottom, -40)
  }
}
