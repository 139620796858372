@import "src/theme/mixins/utils";

.hidden-submit {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

.hidden {
  display: none;
}

.defaultGap{
  @include pxToRem(gap, 8);

  ion-col {
    padding-inline-end: unset;
    padding-inline-start: unset;
  }
}
