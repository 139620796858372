.TextEditorWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.formElement {
  width: 100%;
}

.formField {
  width: 100%;
}
