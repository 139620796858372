@import "src/theme/mixins/utils";

.spacer {
  @include pxToRem(height, 20);

  &.sm {
    @include pxToRem(height, 10);
  }

  &.lg {
    @include pxToRem(height, 30);
  }
}