.accordion {
  overflow: hidden;
  background-color: transparent;


  ion-accordion {
    background-color: transparent;

    --padding-bottom: 24px;
    --padding-top: 24px;


    ion-item {
      margin: 0;

      --background: transparent;
      --inner-padding-start: 0;
    }
  }

  .activeTab {
    background-color: var(--ion-color-tertiary);
  }
}
