@import "src/theme/mixins/fonts";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.formWrapper {
  display: flex;
  width: 100%;
  flex-flow: column;
  @include pxToRem(gap, 8);

  .wrapper {
    position: relative;
    display: flex;
    width: 100%;

    .input {
      width: 100%;
      border-radius: var(--wohnsinn-border-radius);
      background-color: var(--ion-background-color);
      border: 1px solid var(--wohnsinn-border-color);
      @include pxToRem(padding-left, 14);
      @include pxToRem(padding-right, 14);
      @include pxToRem(padding-top, 10);
      @include pxToRem(padding-bottom, 10);

      &:focus {
        border-color: var(--ion-color-primary);
        outline: none;
      }
    }

    .button {
      border-radius: var(--wohnsinn-border-radius);
      border: 1px solid var(--wohnsinn-border-color);
      outline: 1px solid var(--wohnsinn-border-color);
      display: flex;
      align-items: center;
      background-color: var(--ion-color-primary);
      padding: .785rem 1.094rem;
      margin-left: .375rem;

      &:focus {
        border-color: var(--ion-color-primary);
        outline: none;
      }
    }

    .optionWrapper {
      z-index: 10;
      position: absolute;
      top: 10px;
      left: -1px;
      background-color: var(--ion-color-light);
      width: 100%;
      border: 1px solid var(--wohnsinn-border-color);
      margin: 3.5rem .2rem .2rem;
      border-radius: var(--wohnsinn-border-radius);
      overflow: hidden;

      @include for-min-size(lg) {
        max-width: 398px;
      }

      .option {
        padding: 0.7rem;

        &:hover, &:active, &:focus {
          outline: none;
          cursor: pointer;
          background-color: var(--ion-color-primary);
        }
      }
    }
  }
}

.mapWrapper {
  @include pxToRem(margin-bottom, 10);
}
