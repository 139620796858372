@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-danger-tint);

  @include pxToRem(width, 60);
  @include pxToRem(height, 60);
  @include pxToRem(border-radius, 9999);
  @include pxToRem(padding, 8);
  @include pxToRem(margin-bottom, 24);

  svg {
    color: var(--ion-color-danger);
  }
}


.buttonWrapper {
  display: flex;
  width: 100%;

  @include pxToRem(margin-top, 24);
  @include pxToRem(gap, 8);

  @include for-max-size(md) {
    flex-direction: column;
  }

  ion-button {
    flex: 1;
  }
}
