.badge {
  border-radius: 30px;
  line-height: 1px;

  --padding-bottom: 4px;
  --padding-top: 4px;
  --padding-end: 9px;
  --padding-start: 9px;

  &.softenRadius {
    border-radius: var(--wohnsinn-border-radius);
  }

  &.PRIMARY {
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-secondary-contrast);
  }

  &.DANGER {
    --background: var(--ion-color-danger-tint);
    --color: var(--ion-color-danger-contrast);
  }

  &.RED {
    --background: var(--ion-color-danger);
  }

  &.DARK {
    --background: var(--ion-color-dark);
  }

  &.LIGHT {
    --background: var(--ion-background-color);
  }

  &.DARK_TRANSPARENCY {
    --background: rgb(0 0 0 / 60%);
  }

  &.SUCCESS {
    --background: var(--ion-color-success-tint);
    --color: var(--ion-color-success-shade);
  }

  &.DISABLED {
    --background: var(--ion-color-light-shade);
    --color: var(--ion-color-success-shade);
  }

  .icon {
    margin-right: 5px;
    cursor: pointer;
  }
}

