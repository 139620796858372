@import "src/theme/mixins/utils";

.equipmentToggleWrapper {
  @include pxToRem(margin-top, 32);

  > div {
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    display: flex;
    align-items: center;
    @include pxToRem(padding-right, 16);
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-top, 5);
    @include pxToRem(padding-bottom, 5);
  }
}
