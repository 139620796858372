@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.mediaUploadWrapper {
  @include pxToRem(margin-top, 16);
}

.videoInfoWrapper {
  display: flex;
  flex-direction: column;
  @include pxToRem(margin-bottom, 16);
  @include pxToRem(gap, 10);
}

.exampleVideoWrapper {
  background: var(--ion-color-dark);
  border-radius: var(--wohnsinn-border-radius);

  .exampleVideo {
    overflow: hidden;
    border-radius: var(--wohnsinn-border-radius);
    margin: 0 auto;
    max-width: 150px;

    @include for-min-size(md) {
      max-width: 200px;
    }
  }
}
