@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  height: 100%;
  position: relative;

  .swiperWrapper {
    @include for-min-size(lg) {
      &.centerVertical {
        @include pxToRem(margin-top, 120);
      }
    }
  }
}
