@import "src/theme/mixins/landlord-landing";
@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-color-primary);
  @include full-width-background(primary);
  @include landlord-landing-section-padding;

  .logo {
    img {
      background-color: var(--ion-background-color);
      margin: 0 auto;
      @include pxToRem(margin-bottom, 32);
      @include pxToRem(padding, 6);
    }
  }

  .avatar {
    div {
      margin: 0 auto;
      @include pxToRem(margin-top, 32);
      @include pxToRem(margin-bottom, 32);
    }
  }
}
