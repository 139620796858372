@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .marker {
    background-color: black;
    border-radius: 9999px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s;
    @include pxToRem(padding, 3);
    @include pxToRem(padding-left, 8);
    @include pxToRem(padding-right, 8);

    &.unpublished {
      width: 60px;
      height: 60px;
    }

    &.hovered {
      background-color: var(--ion-color-primary);
    }

    &:hover {
      transform: scale(1.2);
    }

    img {
      @include pxToRem(width, 25);
    }
  }

  .apartmentPreview {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include boxShadow;
    @include pxToRem(bottom, 50);
    @include pxToRem(width, 350);

    @include for-min-size(md) {
      bottom: unset;
      @include pxToRem(top, -350);

      &.positionBelow {
        top: unset;
        @include pxToRem(bottom, -350);
      }
    }

    .close {
      background-color: var(--ion-background-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      position: absolute;
      z-index: var(--z-index-fixed);
      color: var(--ion-text-color-accent);
      @include pxToRem(right, 15);
      @include pxToRem(top, 15);
      @include pxToRem(width, 20);
      @include pxToRem(height, 20);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
