@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.iconWrapper {
  background-color: var(--ion-color-danger-tint);

  @include pxToRem(width, 60);
  @include pxToRem(height, 60);
  @include pxToRem(border-radius, 9999);
  @include pxToRem(padding, 8);
  @include pxToRem(margin-bottom, 8);

  &.success {
    background-color: var(--ion-color-success-tint);
  }
}


.list li {
  list-style: disc;
  @include pxToRem(margin-left, 15);
}


.buttonWrapper {
  display: flex;
  width: 100%;

  @include pxToRem(margin-top, 16);
  @include pxToRem(gap, 8);

  @include for-max-size(lg) {
    flex-direction: column;
  }
}