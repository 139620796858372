@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.name {
  @include pxToRem(margin-bottom, 8);
}

.avatarWrapper {
  display: flex;
  justify-content: center;
}


.banner {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  @include pxToRem(top, 55);

  @include for-min-size(md) {
    @include pxToRem(top, 76);
  }
}

.bannerSpace {
  @include pxToRem(height, 60);

  @include for-min-size(md) {
    @include pxToRem(height, 40);
  }
}
