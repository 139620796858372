@import "src/theme/mixins/utils";

.wrapper {
  width: 100%;
  position: relative;
  background: var(--ion-color-light);
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  overflow-y: scroll;
  @include pxToRem(max-height, 130);

  .placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    user-select: none;
    pointer-events: none;
    @include pxToRem(top, 10);
    @include pxToRem(left, 10);

    &.large {
      @include pxToRem(top, 15);
      @include pxToRem(left, 20);
    }
  }
}
