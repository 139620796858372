@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.modalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .userPlusIcon {
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    @include pxToRem(height, 18);

    padding: .7rem;
  }

  .sendInvitationButton {
    margin-top: 1.5rem;
  }

  .plusIcon {
    margin-right: 0.625rem
  }
}