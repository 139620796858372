@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

@mixin landlord-landing-section-padding {

  @include pxToRem(padding-top, 32);
  @include pxToRem(padding-bottom, 32);
  @include pxToRem(padding-right, 8);
  @include pxToRem(padding-left, 8);

  @include for-min-size(md) {
    flex-direction: row;
    @include pxToRem(padding-right, 0);
    @include pxToRem(padding-left, 0);
    @include pxToRem(padding-top, 80);
    @include pxToRem(padding-bottom, 130);
  }

}
