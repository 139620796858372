@import "src/theme/mixins/utils";

.list {
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 8);
}

.item {
  display: flex;
  align-items: center;
  @include pxToRem(gap, 8);
}
