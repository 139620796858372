@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

@mixin minMaxWidth($width) {
  min-width: $width + px !important;
  max-width: $width + px !important;
}

.tableWrapper {
  @include pxToRem(padding, 16);
}

.table {
  .imageCell {
    @include minMaxWidth(100);
  }

  .link {
    display: block;
    cursor: pointer;
    color: var(--ion-text-color);

    &:hover {
      text-decoration: underline;
    }
  }

  .nameCell {
    @include minMaxWidth(180);
  }

  .messagesCell {
    @include minMaxWidth(100);

    .messagesWrapper {
      position: relative;

      .messagesBadge {
        position: absolute;
        left: 0;
        z-index: 1;
        @include pxToRem(top, -5);
      }
    }
  }

  .ratingButtonsCell {
    @include minMaxWidth(180);
  }

  .videoCell {
    @include minMaxWidth(120);
  }

  .jobCell {
    @include minMaxWidth(170);
  }

  .incomeCell {
    @include minMaxWidth(120);
  }

  .videoButton {
    position: relative;
    border-radius: var(--wohnsinn-border-radius);
    overflow: hidden;
    background-color: var(--ion-color-dark);
    box-sizing: border-box;
    @include pxToRem(margin-top, 5);
    @include pxToRem(margin-bottom, 5);

    .playIcon {
      color: var(--ion-color-light);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover .playIcon {
      color: var(--ion-color-light-shade);
    }

    .video {
      @include pxToRem(max-width, 150);
      @include pxToRem(max-height, 75);
    }
  }
}

.mobile {
  background-color: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);
  padding: 1rem;

  .element {
    overflow: hidden;
    border-bottom: 1px solid var(--wohnsinn-border-color);

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .infoWrapper {
        width: 100%;
        display: flex;
        align-items: center;

        @include pxToRem(gap, 16);
        @include pxToRem(padding-top, 16);
        @include pxToRem(padding-bottom, 16);
        @include pxToRem(padding-left, 8);
        @include pxToRem(padding-right, 8);


        .plus {
          color: var(--ion-color-primary);
        }

        .infoText {
          display: flex;
          flex-direction: column;
        }
      }

      .ctaWrapper {
        display: flex;
        @include pxToRem(gap, 16);

        .messagesWrapper {
          position: relative;

          .messagesBadge {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
          }
        }
      }
    }

    &:active,
    &:hover {
      .head {
        background-color: var(--ion-color-tertiary);
      }
    }

    .moreInfo {
      display: none;
      @include pxToRem(padding-top, 8);
      @include pxToRem(padding-bottom, 8);
      @include pxToRem(padding-left, 8);


      &.show {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
