@import "src/theme/mixins/responsive";

.container {
  display: flex;
  flex-direction: column;

  @include for-min-size(md) {
    flex-direction: row;
  }

  .title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    flex-grow: 0;
    flex-shrink: 0;

    @include for-min-size(md) {
      margin-bottom: 0;
      font-size: 1.5rem;
      line-height: 2rem;
      width: 50%;
    }
  }

  .content {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 300;

    @include for-min-size(md) {
      padding-right: 1rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
}

