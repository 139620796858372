@import "src/theme/mixins/utils";

.wrapper {
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  position: relative;

  &.disabled {
    opacity: .6;
  }

  .link {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include pxToRem(height, 250);

    border-radius: var(--wohnsinn-border-radius);
  }


  .icon {
    width: 20px;
  }

  .delete {
    border-radius: 9999px;
    position: absolute;
    background-color: var(--ion-color-light);
    transition: scale .4s;
    @include pxToRem(top, 8);
    @include pxToRem(right, 8);
    @include pxToRem(width, 26);
    @include pxToRem(height, 26);

    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      @include pxToRem(width, 17);
    }

    &:hover {
      scale: 1.1;

    }
  }

  .content {
    @include pxToRem(padding-top, 16);
    @include pxToRem(padding-bottom, 24);
    @include pxToRem(padding-left, 6);
    @include pxToRem(padding-right, 6);
    @include pxToRem(margin-bottom, 24);

    border-bottom: 1px solid var(--wohnsinn-border-color);

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include pxToRem(gap, 10);

      p {
        margin: 0;
      }
    }
  }
}
