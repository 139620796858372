@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.formWrapper {
  position: relative;
  height: 100%;

  @include for-max-size(lg) {
    @include pxToRem(padding-bottom, 80);
  }

  .mobileButtons {
    background-color: var(--ion-background-color);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include pxToRem(padding-top, 7);
    @include pxToRem(padding-bottom, 10);
    @include pxToRem(padding-left, 20);
    @include pxToRem(padding-right, 20);

    @include for-min-size(lg) {
      display: none;
    }
  }

  .desktopButtons {
    display: none;

    @include for-min-size(lg) {
      display: flex;
      justify-content: flex-end;
      @include pxToRem(gap, 12);
    }
  }
}



