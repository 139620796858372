@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

@mixin gridTemplate {
  @include for-min-size(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-min-size(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-min-size(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.wrapper {
  display: flex;
  height: 100%;

  .safeSearch {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--z-index-fixed);
    @include pxToRem(bottom, 80);

    @include for-min-size(lg) {
      @include pxToRem(bottom, 25);
    }
  }

  @include for-min-size(md) {
    padding-top: 4.5rem;
  }
  @include for-min-size(lg) {
    padding-top: 0;
  }

  .toggleButton {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    @include pxToRem(bottom, 80);

    @include for-min-size(md) {
      display: block;
      @include pxToRem(bottom, 60);
    }
  }

  .content {
    width: 100%;
    @include pxToRem(padding-top, 32);

    @include for-min-size(lg) {
      @include pxToRem(padding-top, 24);
    }

    .filterWrapper {
      top: 0;
      display: block;
      background-color: var(--ion-background-color);
      border-bottom: 1px solid var(--ion-background-color);
      position: fixed;
      width: 100%;
      z-index: var(--z-index-fixed);
      transition: border .4s;
      @include pxToRem(padding-top, 16);
      @include pxToRem(padding-bottom, 16);
      @include pxToRem(padding-left, 8);
      @include pxToRem(padding-right, 8);

      &.scrolled {
        border-color: var(--wohnsinn-border-color);
      }

      @include for-min-size(lg) {
        display: none;
      }
    }

    .moreFilters {
      display: none;
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 16);

      @include for-min-size(md) {
        display: block;
        @include pxToRem(gap, 10);
      }
    }

    .emptyApartments {
      @include pxToRem(padding-top, 64);
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 32);

      @include for-min-size(md) {
        @include pxToRem(padding-top, 16);
      }
    }

    .cardWrapper {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      @include pxToRem(gap, 12);
      @include pxToRem(padding-top, 16);
      @include pxToRem(padding-bottom, 32);
      @include pxToRem(margin-top, 35);
      @include pxToRem(border-top-right-radius, 10);
      @include pxToRem(border-top-left-radius, 10);
      @include gridTemplate;

      @include for-min-size(md) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding-top: 0;
        margin-top: 0;
      }

      &.moreApartments {
        @include pxToRem(margin-top, 24);

        @include for-min-size(md) {
          @include pxToRem(margin-top, 36);
        }

        @include gridTemplate;
        @include for-min-size(sm) {
          .moreApartmentHeadline {
            grid-column: 1 / -1;
          }
        }

      }

      .card {
        width: 100%;
        border: 2px solid transparent;
        overflow: hidden;
        border-radius: var(--wohnsinn-border-radius);

        &.hovered {
          border-color: var(--ion-color-primary);
        }
      }
    }
  }

  .pagination {
    @include pxToRem(margin-top, 16);
    @include pxToRem(margin-bottom, 32);
    @include pxToRem(padding-bottom, 32);
  }
}

