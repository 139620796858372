@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ratingButtons {
  align-items: center;
  background-color: var(--ion-background-color);
  border-top: 1px solid var(--wohnsinn-border-color);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  transform: translateY(100%);
  transition: transform .25s ease-in, opacity .25s ease-in;
  z-index: 99;
  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-bottom, 16);

  @include for-min-size(md) {
    @include pxToRem(left, 200);
  }

  @include for-min-size(lg) {
    @include pxToRem(left, 400);
  }

  @include for-min-size(xl) {
    @include pxToRem(left, 700);
  }

  &.show {
    transition: transform .25s ease-out, opacity .25s ease-out;
    transform: translateX(0);
  }

  @media print {
    display: none;
  }
}

.wrapper {
  @include pxToRem(padding-bottom, 132);

  .content {
    @include pxToRem(padding-left, 8);
    @include pxToRem(padding-right, 8);

    @include for-min-size(md) {
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
    }

    @include for-min-size(lg) {
      @include pxToRem(padding-left, 32);
      @include pxToRem(padding-right, 32);
    }
  }

  .noPrint {
    @media print {
      display: none;
    }
  }
}
