@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.swiperWrapper {
  cursor: pointer;
  position: relative;

  .link {
    display: none;
    @include for-min-size(md) {
      display: block;
    }

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }


  .buttons {
    display: none;

    @include for-min-size(md) {
      display: block;
      position: absolute;
      top: 50%;
      z-index: 3;
      width: 30px;
      height: 30px;
      background-color: var(--ion-background-color);
      border-radius: 9999px;
      transform: translateY(-50%);
      transition: opacity .4s;
      @include boxShadow;

      @include for-min-size(md) {
        opacity: 0;
        visibility: hidden;
      }

      &.prev {
        left: 10px;
      }

      &.next {
        right: 10px;
      }
    }

  }

  &:hover {
    .buttons {
      visibility: visible;
      opacity: 1;
    }
  }


  .slides {
    display: flex;
    aspect-ratio: 3 / 2;
    overflow: hidden;

    .slide {
      background-size: cover;
      background-position: center;
    }
  }

  .dotsWrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: flex;
    gap: 5px;
    transform: translateX(-50%);
    z-index: var(--z-index-sticky);

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      background: white;
      opacity: .6;

      &.active {
        opacity: 1;
      }
    }
  }

  .indexIndicator {
    background: rgb(0 0 0 / 40%);
    color: var(--ion-color-light);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-sticky);
    font-weight: bold;
    @include pxToRem(font-size, 12);
    @include pxToRem(border-radius, 10);
    @include pxToRem(height, 32);
    @include pxToRem(width, 64);
    @include pxToRem(right, 6);
    @include pxToRem(top, 6);
  }
}

