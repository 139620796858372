@import "src/theme/mixins/landlord-landing";
@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-color-tertiary);
  @include full-width-background(tertiary);
  @include landlord-landing-section-padding;

  .customerWrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--ion-background-color);
    border-radius: var(--wohnsinn-border-radius);
    overflow: hidden;
    @include pxToRem(margin-top, 48);

    @include for-min-size(md) {
      flex-direction: row;
    }

    > * {
      flex: 1;
      text-align: center;
      display: flex;
    }

    .contentWrapper {
      @include for-max-size(md) {
        border-bottom: 1px solid var(--wohnsinn-border-color);

        &:last-of-type {
          border-bottom: none;
        }
      }


      @include for-min-size(md) {
        border-right: 1px solid var(--wohnsinn-border-color);

        &:last-of-type {
          border-right: none;
        }
      }

      display: flex;
      flex-direction: column;

      .title {
        @include pxToRem(padding-top, 24);
      }

      .content {
        @include pxToRem(padding-bottom, 24);
        @include pxToRem(padding-top, 32);

        .imgWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          @include pxToRem(height, 120);

          > img {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
