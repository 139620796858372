@import "src/theme/mixins/utils";
@import "src/theme/mixins/fonts";

.tag {
  background-color: var(--ion-color-light);
  color: var(--ion-text-color);
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: 9999px;

  @include pxToRem(padding-top, 2);
  @include pxToRem(padding-bottom, 2);
  @include pxToRem(padding-left, 8);
  @include pxToRem(padding-right, 8);


  &.small {
    @include pxToRem(padding-top, 0);
    @include pxToRem(padding-bottom, 0);
    @include pxToRem(padding-left, 6);
    @include pxToRem(padding-right, 6);
  }

  &.BLUE {
    border: white;
    background-color: var(--ion-color-tertiary);
  }
}
