@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.modalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    @include pxToRem(width, 120);
    @include pxToRem(margin-bottom, 16);
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: auto;
    border-top: 1px solid var( --wohnsinn-border-color);

    @include pxToRem(padding-top, 32);
    @include pxToRem(margin-top, 16);
    @include pxToRem(gap, 8);

    @include for-max-size(lg) {
      flex-direction: column;
    }
  }
}