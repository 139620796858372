@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.overflowRow {
  overflow-x: scroll;
}

.emptyTableRow {
  background-color: var(--ion-color-tertiary);
  @include pxToRem(margin-top, 12);
  @include pxToRem(padding, 16);
}

.segmentLabel {
  display: none;

  @include for-min-size(md) {
    display: inline;
  }
}

.segmentIcon {
  display: inline-block;
  @include pxToRem(margin-left, 6);
  @include pxToRem(width, 20);
}
