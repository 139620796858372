@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.stepper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @include pxToRem(gap, 10);
  @include pxToRem(max-width, 800);
  @include pxToRem(margin-bottom, 30);

  @include for-min-size(md) {
    justify-content: space-between;
    gap: 0;
    @include pxToRem(margin-top, 30);
    @include pxToRem(margin-bottom, 50);
  }

  .mobileLabel {
    @include for-min-size(md) {
      display: none;
    }
  }

  .divider {
    flex-grow: 1;
    border-bottom: 1px solid var(--wohnsinn-border-color);

    @include for-max-size(md) {
      display: none;
    }

    &.active {
      border-color: var(--ion-color-primary);
    }
  }
}

.step {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  border-radius: 9999px;
  border: 1px solid transparent;
  transition: border .3s;

  &:hover {
    border-color: var(--ion-color-primary);
  }

  .label {
    display: none;

    @include for-min-size(md) {
      white-space: nowrap;
      position: absolute;
      display: inline;

      @include pxToRem(top, 24);
    }
  }
}
