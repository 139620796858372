@import "../../../../theme/mixins/responsive";

.tunnelBackground {
  @include for-min-size(lg) {
    background-image: url("../../../../../public/assets/images/search-tunnel/background.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
}

.appLogo {
  height: 26px;
  width: 150px;
  background-repeat: no-repeat;
}