@import "src/theme/mixins/utils";
@import "src/theme/mixins/animations";
@import "src/theme/mixins/fonts";


.apartmentCard {
  background-color: var(--ion-background-color);
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  transition: box-shadow .3s, border-color .3s;
  @include pxToRem(max-height, 200);
}

.contentWrapper {
  display: flex;
  border-radius: var(--wohnsinn-border-radius);
}

.content {
  width: 50%;
  transition: border .3s;
  @include pxToRem(padding, 12);

  .bottom {
    @include pxToRem(margin-top, 8);
  }
}

.image {
  border-radius: var(--wohnsinn-border-radius);
  background-size: cover;
  @include pxToRem(width, 120);
  @include pxToRem(max-height, 200);
}
