@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ctaButton {
  // Remove shadow dom styling
  --box-shadow: none;
  --background: none;
  --background-hover: none;
  --border: none;
  --border-radius: var(--wohnsinn-border-radius);

  margin: 0;
  text-transform: none;
  border: 1px solid var(--ion-color-primary);
  transition: all .3s;
  border-radius: var(--wohnsinn-border-radius);

  &.rounded {
    @include pxToRem(border-radius, 9999);

    --border-radius: 9999px;
  }


  @include pxToRem(height, 40);

  svg {
    color: var(--ion-text-color-light);
  }

  &:hover {
    background: var(--ion-color-primary-tint);
  }

  &.expandMobile {
    @include for-max-size(md) {
      width: 100%;
    }
  }

  &.tiny {
    --padding-start: 6px;
    --padding-end: 6px;

    @include pxToRem(height, 26);
  }

  &.small {
    --padding-start: 8px;
    --padding-end: 8px;

    @include pxToRem(height, 32);
  }

  &.big {
    --padding-start: 32px;
    --padding-end: 32px;

    @include pxToRem(height, 66);
  }

  &.shadow {
    @include boxShadow;
  }

  &.hideText {
    --padding-start: 0;
    --padding-end: 0;

    @include pxToRem(width, 40);

    &.tiny {
      @include pxToRem(width, 26);
    }

    &.small {
      @include pxToRem(width, 32);
    }

    &.big {
      --padding-start: 14px;
      --padding-end: 14px;

      @include pxToRem(width, 66);
    }
  }

  &.primary {
    background: var(--ion-color-primary);
    transition: background-color .4s;

    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }

  &.danger {
    background-color: var(--ion-color-danger);
    border-color: var(--ion-color-danger);

    span,
    p {
      color: var(--ion-text-color-light);
    }

    &:hover {
      background-color: var(--ion-color-danger-shade);
      border-color: var(--ion-color-danger-shade);
      color: var(--ion-color-danger-shade);
    }
  }

  &.secondary {
    border-color: var(--wohnsinn-border-color);
    background-color: var(--ion-background-color);

    svg {
      color: var(--ion-color-primary);
    }

    p,
    span {
      color: var(--ion-text-color);
    }

    &:hover {
      border-color: var(--ion-color-tertiary);
      color: var(--ion-color-primary);
      @include boxShadow;
    }
  }

  &.tertiary-light,
  &.tertiary {
    border-color: transparent;
    background-color: transparent;
    color: var(--ion-text-color);

    &:hover {
      color: var(--ion-color-primary);
      text-decoration: underline;
    }

    svg {
      color: var(--ion-text-color-accent);
    }
  }


  &.danger-inverted {
    color: var(--ion-color-danger);
    border-color: var(--wohnsinn-border-color);
    background-color: var(--ion-background-color);

    &:hover {
      background-color: var(--ion-background-color);
      border-color: var(--ion-color-danger-shade);
      color: var(--ion-color-danger-shade);
    }

    .icon svg {
      color: var(--ion-color-danger);
    }
  }

  &.secondary-inverted {
    border-color: var(--ion-color-tertiary);
    background-color: var(--ion-color-tertiary);
    color: var(--ion-color-primary);

    &:hover {
      border-color: var(--wohnsinn-border-color);
      background-color: var(--ion-background-color);
      color: var(--ion-text-color);
    }
  }

  .img {
    width: 16px;
    @include pxToRem(width, 16);

    &.big {
      @include pxToRem(width, 32);
    }
  }

  .img,
  .icon {
    @include pxToRem(margin-right, 6);

    &.big {
      @include pxToRem(font-size, 34);
    }

    &.hideText {
      margin-right: 0;
    }
  }
}
