@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  position: relative;
}

.apartmentInformation {
  background-color: var(--ion-background-color);
  position: relative;
  max-width: 100%;
  width: 100%;

  @include for-min-size(md) {
    border-right: 1px solid var(--wohnsinn-border-color);
    height: calc(100vh - var(--desktop-menu-height));
    @include pxToRem(max-width, 500);

    overflow-y: scroll;
  }

  @include for-min-size(lg) {
    @include pxToRem(max-width, 500);
  }

}

.map {
  display: none;
  position: relative;
  width: calc(100% - 400px);

  @include for-min-size(md) {
    display: block;
  }

  .disabledTagWrapper {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
