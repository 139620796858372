.editor-disabled,
.editor-input {
  min-height: 2.88rem;
  background-color: var(--ion-background-color);
  border: none;
  border-radius: 10px;
  padding: 10px;

  &.large {
    padding: 20px;
    height: 8.6rem;
    overflow: auto;
  }
}

.editor-disabled {
  height: 40px;
  cursor: not-allowed;
}

.editor-paragraph {
  width: 100%;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}
