@import "../../../../theme/mixins/responsive";
@import "../../../../theme/mixins/utils";

h1.headline {
  @include pxToRem(margin-top, 5);
  @include pxToRem(margin-bottom, 10);
}

h2.headline {
  @include pxToRem(margin-top, 5);
  @include pxToRem(margin-bottom, 5);
}

h3.headline {
  @include pxToRem(margin-top, 5);
  @include pxToRem(margin-bottom, 5);
}

h4.headline {
  @include pxToRem(margin-top, 5);
  @include pxToRem(margin-bottom, 5);
}

.headline {
  color: var(--ion-text-color);
  font-weight: 700;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.noMargin {
    margin: 0;
  }


  // COLORS
  &.HEADLINE_COLOR_DARK {
    color: var(--ion-text-color);
  }

  &.HEADLINE_COLOR_LIGHT {
    color: var(--ion-text-color-light);
  }

  &.HEADLINE_COLOR_PRIMARY {
    color: var(--ion-color-primary);
  }

  @include for-max-size(md) {
    &.mobile-left {
      text-align: left;
    }

    &.mobile-center {
      text-align: center;
    }

    &.mobile-right {
      text-align: right;
    }
  }
}

.h1 {
  @include pxToRem(font-size, 24);
  @include pxToRem(line-height, 26);

  @include for-min-size(lg) {
    @include pxToRem(font-size, 30);
    @include pxToRem(line-height, 38);
  }
}

.h2 {
  @include pxToRem(font-size, 22);
  @include pxToRem(line-height, 30);

  @include for-min-size(lg) {
    @include pxToRem(font-size, 24);
    @include pxToRem(line-height, 30);
  }
}

.h3 {
  @include pxToRem(font-size, 16);
  @include pxToRem(line-height, 20);

  @include for-min-size(lg) {
    @include pxToRem(font-size, 18);
    @include pxToRem(line-height, 24);
  }
}

.display {
  @include pxToRem(font-size, 32);
  @include pxToRem(line-height, 36);

  @include for-min-size(lg) {
    @include pxToRem(font-size, 50);
    @include pxToRem(line-height, 54);
  }
}
