@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.actionButton,
.editButton {
  &:hover svg {
    color: var(--ion-color-primary);
  }
}

.apartmentMediaItem {
  position: relative;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  cursor: move;
  border: 2px solid transparent;
  @include boxShadow;

  &.active {
    border: 2px solid var(--ion-color-primary);
  }

  .actionButtonWrapper {
    position: absolute;
    display: flex;
    @include pxToRem(gap, 12);
    @include pxToRem(top, 12);
    @include pxToRem(right, 12);

    .actionButton {
      background-color: var(--ion-background-color);
      display: flex;
      justify-content: center;
      align-items: center;
      @include pxToRem(border-radius, 5);
      @include pxToRem(width, 25);
      @include pxToRem(height, 25);
      @include pxToRem(padding, 3);


      &.actionDelete {
        &:hover svg {
          color: var(--ion-color-danger);
        }
      }
    }
  }




  .image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include pxToRem(height, 100);

    @include for-min-size(lg) {
      @include pxToRem(height, 140);
    }
  }

  .description {
    display: flex;
    justify-content: space-between;
    background-color: var(--ion-background-color);
    @include pxToRem(padding, 10);
    @include pxToRem(gap, 10);
  }
}
