@import "src/theme/mixins/utils";

.ws-table {
  width: 100%;

  tbody tr {
    height: 60px;
    border-top: 1px solid var(--wohnsinn-border-color);

    &:nth-child(2n) {
      background-color: var(--ion-background-color);
    }

    &:hover {
      background-color: var(--ion-color-tertiary-tint);
    }

    &:last-of-type {
      border-bottom: 1px solid var(--wohnsinn-border-color);
    }
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-transform: uppercase;
  }

  td {
    padding-left: 3px;
    padding-right: 3px;
    @include pxToRem(padding-top, 16);
    @include pxToRem(padding-bottom, 16);
    @include pxToRem(min-width, 150);
  }

  thead {
    background-color: var(--ion-color-light);

    th:last-of-type {
      border-top-right-radius: 10px;
    }

    th:first-of-type {
      border-top-left-radius: 10px;
    }
  }
}
