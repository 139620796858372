@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";


.mobileHeader {
  border-bottom: 1px solid transparent;
  transition: all .3s;
  @include pxToRem(padding-left, 5);
  @include pxToRem(padding-right, 5);

  .toolBar {
    --background: var(--ion-background-color);
  }

  &.borderBottom,
  &.scrolled {
    border-bottom-color: var(--wohnsinn-border-color);
  }
}


.wrapper {
  background-color: var(--ion-background-color);
  @include pxToRem(padding-top, 12);
  @include pxToRem(padding-bottom, 12);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
}

.content {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(.fullWidth) {
    max-width: var(--ion-grid-width);
  }

  @include for-min-size(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.appLogo {
  background-repeat: no-repeat;

  @include pxToRem(margin-top, 13);
  @include pxToRem(margin-bottom, 13);
  @include pxToRem(height, 26);
  @include pxToRem(width, 203);
}

.accountSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include pxToRem(gap, 10);
  @include pxToRem(width, 203);

  ion-button {
    margin-bottom: 0;
  }

  .linkElement {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);
    font-weight: bold;

    @include pxToRem(border-radius, 9999);
    @include pxToRem(height, 30);
    @include pxToRem(width, 30);
  }
}
