@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.view {
  display: grid;
  grid-template-columns: 1fr;
  @include pxToRem(padding-top, 48);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(gap, 16);

  @include for-min-size(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-min-size(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .loadingApartmentCard {
    border-radius: var(--wohnsinn-border-radius);
    border: 1px solid var(--wohnsinn-border-color);
    padding: 10px;

    .image {
      width: 100%;
      border-radius: var(--wohnsinn-border-radius);
      aspect-ratio: 3/2;
      @include pxToRem(margin-bottom, 12);
    }

    .text {
      @include pxToRem(border-radius, 5);
      @include pxToRem(margin-top, 10);
      @include pxToRem(height, 20);
    }

    .text,
    .image {
      @include loadingSkeleton;
    }
  }
}

