.fullscreen {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingAnimation {
  height: 30px;
  display: flex;
  gap: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;

  &.BIG {
    height: 110px;
  }
}

.loader {
  background-color: var(--ion-color-primary);
  animation: animation 1s infinite ease-in-out;
  border-radius: 2px;
  width: 5px;

  &.BIG {
    width: 20px;
    animation: animationBig 1s infinite ease-in-out;
  }

  &.PRIMARY {
    background-color: var(--ion-color-primary);
  }
}

.loader:nth-child(2) {
  animation-delay: -0.16s;
}

.loader:nth-child(3) {
  animation-delay: -0.32s;
}

@keyframes animation {
  0%,
  80%,
  100% {
    height: 17px;
  }

  40% {
    height: 25px;
  }
}

@keyframes animationBig {
  0%,
  80%,
  100% {
    height: calc(17px * 4);
  }

  40% {
    height: calc(25px * 4);
  }
}
