@import "src/theme/mixins/utils";

.aboutMeText {
  color: var(--ion-text-color);
  font-weight: 300;
  letter-spacing: .25pt;
  @include pxToRem(font-size, 16);
  @include pxToRem(line-height, 18);

  display: block;
  overflow: auto;
  @include pxToRem(max-height, 200);

  @media print {
    max-height: 130px;
  }
}

.informationSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include pxToRem(padding, 5);
  @include pxToRem(gap, 16);

  @media print {
    gap: 0;
    @include pxToRem(padding-bottom, 10);
  }
}

.video {
  background-color: black;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  @include pxToRem(max-height, 270);
}

.hidePrint {
  @media print {
    display: none;
  }
}

.showPrint {
  display: none;
  @media print {
    display: block;
  }
}
