@import "src/theme/mixins/utils";


.suggestionsTitle {
  @include pxToRem(margin-bottom, 12);
}


.suggestionsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include pxToRem(gap, 10);
  @include pxToRem(margin-top, 16);
  @include pxToRem(margin-bottom, 16);
}
