@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ctaBar {
  display: flex;
  align-items: center;

  @include pxToRem(gap, 10);

  @include for-max-size(lg) {
    @include pxToRem(margin-top, 20);

    flex-direction: column;
    width: 100%;
  }
}

.fullWidthButton {
  width: 100%;
}
