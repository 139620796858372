@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/fonts";

.wrapper {
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-background-color);
  @include pxToRem(padding, 12);
  @include pxToRem(padding-bottom, 10);
  @include pxToRem(padding-top, 10);
  @include pxToRem(margin-bottom, 8);

  &.isActive,
  &:hover {
    background-color: var(--ion-color-tertiary);
  }

  &.isChatDisabled {
    background-color: var(--ion-color-disabled);
    opacity: .6;
    pointer-events: none;
  }

  .newMessageIndicator {
    position: absolute;
    background-color: transparent;
    @include pxToRem(border-radius, 9999);
    @include pxToRem(width, 8);
    @include pxToRem(height, 8);
    @include pxToRem(top, 16);
    @include pxToRem(right, 5);

    &.show {
      background-color: var(--ion-color-danger);
    }
  }

  .badgeWrapper {
    position: absolute;
    @include pxToRem(right, 18);
  }

  .apartmentInfoWrapper {
    display: flex;
    flex-grow: 1;
    @include pxToRem(gap, 16);

    .apartmentImage {
      border-radius: var(--wohnsinn-border-radius);
      background-size: cover;
      background-repeat: no-repeat;
      @include pxToRem(width, 100);
      @include pxToRem(min-width, 100);
      @include pxToRem(height, 75);
    }

    .apartmentText {
      width: calc(100% - 180px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;


      .message {
        .text {
          width: calc(100% - 1px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          @include text-small("light");

          p {
            display: inline;

            span {
              font-weight: var(--font-weight--light);
            }
          }


          .bold {
            p span {
              font-weight: var(--font-weight--bold);
            }
          }
        }
      }
    }
  }
}
