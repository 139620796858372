@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  height: 100%;

  > .swiper {
    height: 100%;
  }

  .howToSlide {
    width: 100%;

    .applyWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .image {
        @include for-max-size(md) {
          max-width: 180px;
          margin-top: 40px;
        }

        @include for-min-size(md) {
          max-height: 200px;
          margin-bottom: 100px;
          margin-top: 40px;
        }
      }

      .button {
        position: absolute;
        bottom: 0;
        width: 100%;
        @include pxToRem(padding-left, 16);
        @include pxToRem(padding-right, 16);
      }
    }

    .ratingsWrapper {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      @include pxToRem(gap, 32);

      @include for-min-size(md) {
        position: static;
        margin-top: 100px;
      }

      .buttonAnimation {
        @include buttonAnimation;
      }

      img {
        @include pxToRem(width, 32)
      }

      .arrowWrapper {
        transform: translateY(-20px);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;

        button {
          @include boxShadow;
        }

        .click {
          transform: rotate(-5deg);
          font-style: italic;
        }

        .arrowDown {
          color: var(--ion-color-medium-tint);
          animation: bounce-out-down 1.5s ease infinite;

          &.maybe {
            color: var(--ion-color-primary);
          }

          &.nope {
            color: var(--ion-color-danger);
          }
        }

        @keyframes bounce-out-down {
          0% {
            transform: translateY(0);
          }

          20% {
            transform: translateY(-20px);
          }

          100% {
            transform: translateY(20px);
          }
        }
      }

    }
  }

}

