@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageWrapper {
  max-width: 200px;
}

.contentWrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  @include for-max-size(lg) {
    max-width: 250px;
  }
}
