@import "src/theme/mixins/utils";

.bookmarkIcon {
  position: relative;
  color: var(--ion-text-color);

  .icon {
    @include pxToRem(width, 30);

    margin: 1rem 0 1rem 1rem;
  }

  &.animate {
    animation: animateBookmark 1s;
  }

  .badgeWrapper {
    position: absolute;
    right: 50%;
    @include pxToRem(top, 8);

    div {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes animateBookmark {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}
