@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.ctaBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @include pxToRem(gap, 5);
  @include pxToRem(margin-top, 10);

  @include for-min-size(md) {
    flex-wrap: nowrap;
  }
}
