ion-popover {
  --color: white;
}

ion-popover ion-content {
  --background: var(--ion-color-tertiary);
  --padding-top: 4px;
  --padding-bottom: 4px;
  --padding-start: 8px;
  --padding-end: 8px;
}


