@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.loadingApartmentCard {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding-top, 50);
  @include boxShadow;

  @include for-min-size(md) {
    @include pxToRem(width, 500);
  }

  .text,
  .image {
    width: 100%;
    @include loadingSkeleton;
  }

  .image {
    @include pxToRem(height, 170);
    @include pxToRem(margin-bottom, 16);
  }

  .textWrapper {
    @include pxToRem(padding-right, 16);
    @include pxToRem(padding-left, 16);
    @include pxToRem(margin-bottom, 32);

    .text {
      @include pxToRem(height, 16);
      @include pxToRem(margin-bottom, 8);
    }
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    @include pxToRem(gap, 16);

    .button {
      @include pxToRem(width, 70);
      @include pxToRem(height, 70);
      @include loadingSkeleton;
    }
  }
}

