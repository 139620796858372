@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.grid {
  position: relative;
  height: 100%;

  @include for-max-size(lg) {
    --ion-grid-padding-top: 0;
    @include pxToRem(padding-top, 5);
    @include pxToRem(padding-bottom, 5);
    @include pxToRem(padding-left, 15);
    @include pxToRem(padding-right, 15);
  }

  &.noHorizontalPadding {
    padding-left: 0;
    padding-right: 0;
  }

  &.removePadding {
    --ion-grid-padding: 0;

    padding: 0;
  }
}

.placeholderToolbar {
  @include pxToRem(height, 44);

  display: flex;
  justify-content: center;

  @include for-min-size(md) {
    display: none;
  }
}
