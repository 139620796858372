@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.backDrop {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgb(0 0 0 / 30%);
  opacity: 0;
  z-index: 0;
  visibility: hidden;
  transition: opacity .25s ease-in;

  &.show {
    transition: opacity .25s ease-out;
    visibility: visible;
    opacity: 1;
  }
}


.wrapper {
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 40%) 0 28px 48px 0;
  position: absolute;
  background-color: var(--ion-background-color);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translateX(110%);
  transition: transform .25s ease-in, opacity .25s ease-in;

  @media print {
    width: 100%;
    height: 100%;
  }

  &.small {
    left: unset;
    width: 100%;

    @include for-min-size(md) {
      @include pxToRem(width, 500);
    }
  }

  &:not(.small) {
    @include for-min-size(md) {
      @include pxToRem(left, 200);
    }

    @include for-min-size(lg) {
      @include pxToRem(left, 400);
    }

    @include for-min-size(xl) {
      @include pxToRem(left, 700);
    }
  }

  &.show {
    transition: transform .25s ease-out, opacity .25s ease-out;
    transform: translateX(0);
  }

  .content {
    @include pxToRem(padding-left, 8);
    @include pxToRem(padding-right, 8);

    @include for-min-size(md) {
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
    }

    @include for-min-size(lg) {
      @include pxToRem(padding-left, 32);
      @include pxToRem(padding-right, 32);
    }
  }
}
