@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.userInfo {
  display: flex;
  align-items: flex-end;
  @include pxToRem(gap, 16);
  @include pxToRem(margin-top, -24);

  @include for-min-size(md) {
    @include pxToRem(margin-top, -48);
  }

  @media print {
    margin-top: 0;
  }
}
