@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(margin-top, -17);

  @media print {
    @include pxToRem(margin-top, -13);
  }
}

.item {
  --padding-start: 0 !important;
  --background: var(--ion-background-color);

  .label {
    text-overflow: clip;
    white-space: normal;

    @media print {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
