@import "src/theme/mixins/utils";

.mobileHeaderButton {
  padding-left: 8px;
  padding-right: 8px;
  margin: 0;
  @include pxToRem(padding-top, 6);
  @include pxToRem(padding-bottom, 6);

  svg {
    color: var(--ion-color-dark);

    &.showText {
      margin-left: 10px;
    }
  }
}

