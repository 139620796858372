@import "src/theme/mixins/fonts";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";


.poiWrapper {
  background-color: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);
  box-shadow: 5px 5px 6px -4px rgb(0 0 0 / 30%);
  position: absolute;
  z-index: var(--z-index-base);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  left: 50%;
  transform: translateX(-50%);
  @include pxToRem(gap, 4);
  @include pxToRem(padding, 16);
  @include pxToRem(row-gap, 16);
  @include pxToRem(bottom, 48);

  @include for-min-size(lg) {
    transform: translateX(0);
    @include pxToRem(left, 8);
    @include pxToRem(bottom, 88);
    @include pxToRem(padding, 16);
    @include pxToRem(padding-left, 4);
    @include pxToRem(padding-right, 4);
    @include pxToRem(padding-top, 8);
    @include pxToRem(padding-bottom, 8);
  }
}

.poiButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include pxToRem(gap, 4);
}

.poiIcon {
  background-color: var(--ion-color-light);
  width: 50px;
  height: 50px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;

  &.poiIconRestaurant {
    color: #DE1873;

    &.active {
      background-color: #DE1873;
      color: white;
    }
  }

  &.poiIconTakeAway {
    color: greenyellow;

    &.active {
      background-color: greenyellow;
      color: white;
    }
  }

  &.poiIconTrains {
    color: yellow;

    &.active {
      background-color: yellow;
      color: white;
    }
  }

  &.poiIconSchool {
    color: #188ADE;

    &.active {
      background-color: #188ADE;
      color: white;
    }
  }

  &.poiIconPharmacy {
    color: #FFA700;

    &.active {
      background-color: #FFA700;
      color: white;
    }
  }

  &.poiIconKita {
    color: #29D0A3;

    &.active {
      background-color: #29D0A3;
      color: white;
    }
  }

  &.poiIconHospital {
    color: #8A18DE;

    &.active {
      background-color: #8A18DE;
      color: white;
    }
  }

  &.poiIconSuperMarket {
    color: #18CCDE;

    &.active {
      background-color: #18CCDE;
      color: white;
    }
  }

  &.poiIconFastFood {
    color: #A1DE18;

    &.active {
      background-color: #A1DE18;
      color: white;
    }
  }

  svg {
    font-size: 1.25rem;
  }
}

.poiFont {
  @include text-tiny;
}
