@import "src/theme/mixins/utils";

.optionListItem {
  display: flex;
  width: 100%;
  flex-flow: row;
  border: var(--wohnsinn-border-color) 1px solid;
  border-radius: var(--wohnsinn-border-radius);
  align-items: flex-start;
  cursor: pointer;
  transition: all .3s;

  @include pxToRem(gap, 16);
  @include pxToRem(padding, 16);

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-secondary);
    border-radius: 99rem;
    @include pxToRem(min-width, 32);
    @include pxToRem(min-height, 32);
  }

  .textWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  .checkMarkWrapper {
    border: var(--wohnsinn-border-color) 1px solid;
    border-radius: 50%;
    @include pxToRem(min-width, 16);
    @include pxToRem(min-height, 16);
  }
}


.optionListWrapper {
  display: flex;
  width: 100%;
  flex-flow: column;
  @include pxToRem(gap, 16);

  .input {
    &:hover,
    &:checked {
      + .optionListItem {
        border: var(--ion-color-primary) 1px solid;
        background-color: var(--ion-color-tertiary);


      }
    }

    &:checked + .optionListItem .checkMarkWrapper {
      background-image: url('../../../../public/assets/icon/check.svg');
      background-size: contain;
      border: transparent;
      background-repeat: no-repeat;
    }
  }
}

