@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);

  @include pxToRem(padding, 32);

  .content {
    margin: 0 auto;
    @include pxToRem(max-width, 760);
  }

  .image {
    margin: 0 auto;
  }
}
