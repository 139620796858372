@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";


.wrapper {
  display: flex;
  flex-grow: 1;
  background-color: var(--ion-background-color);
  border: 1px solid var(--wohnsinn-border-color);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow .2s;
  @include pxToRem(border-radius, 9999);
  @include pxToRem(padding, 3);
  @include pxToRem(max-width, 600);

  &.active,
  &:hover {
    @include boxShadow($light: true);
  }

  .filterToggle {
    display: none;

    @include for-min-size(md) {
      display: flex;
      flex-direction: column;
    }
  }

  .location,
  .filterToggle {
    flex-grow: 1;
    border-right: 1px solid var(--wohnsinn-border-color);
    transition: width .2s;
    overflow: hidden;
    white-space: nowrap;
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-right, 16);
    @include pxToRem(padding-top, 0);
    @include pxToRem(padding-bottom, 0);


    &:hover,
    &.active {
      span {
        color: var(--ion-color-primary);
      }
    }
  }

  /* stylelint-disable */
  .location {
    @include pxToRem(border-top-left-radius, 9999);
    @include pxToRem(border-bottom-left-radius, 9999);
    @include pxToRem(padding-left, 24);
    @include pxToRem(padding-right, 16);
    border-right: none;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include for-min-size(md) {
      border-right: 1px solid var(--wohnsinn-border-color);
      @include pxToRem(max-width, 220);
    }
  }

  .filterToggle {
    &:last-of-type {
      border-right: none;
    }
  }
  /* stylelint-enable */
  .searchBtn {
    background-color: var(--ion-color-primary);
  }

}
