@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(margin-bottom, 24);

  .link {
    color: var(--ion-color-primary);
    @include pxToRem(font-size, 12);
  }
}
