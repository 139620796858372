@import "src/theme/mixins/utils";

.wrapper {
  border: 0 dashed transparent;
  transition: all .3s;

  &.activeDrag {
    border-width: 5px;
    border-color: var(--ion-color-secondary);
    @include pxToRem(padding, 8);
  }
}

.dropZone {
  background-color: var(--ion-color-secondary);
  border-radius: var(--wohnsinn-border-radius);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include pxToRem(padding, 16);

  .uploadIconWrapper {
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;
    align-items: center;

    @include pxToRem(border-radius, 9999);
    @include pxToRem(height, 55);
    @include pxToRem(width, 55);
  }

}
