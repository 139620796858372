@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--ion-color-tertiary);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @include pxToRem(gap, 5);
  @include pxToRem(padding-top, 6);
  @include pxToRem(padding-bottom, 6);

  @include for-min-size(md) {
    flex-direction: row;
  }

  .leftRightPart {
    text-align: center;
    @include pxToRem(width, 150);
  }
}
