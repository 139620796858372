.cropWrapper {
  padding-top: 1rem;
  display: flex;
  flex-flow: column;
}

.croppedImage {
  border-radius: 9999px;
}

.buttonWrapper {
  margin-top: 1rem;
  align-self: center;
}