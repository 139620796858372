@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.ratings {
  width: 100%;
  display: flex;
  justify-content: center;
  @include pxToRem(gap, 16);

  &.big {
    @include pxToRem(gap, 24);
  }

  .ratingIcon {
    @include pxToRem(width, 30);
  }
}
