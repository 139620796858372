@import "src/theme/mixins/utils";

.floating-wrapper {
  @include pxToRem(margin-bottom, 16);

  .floating-field {
    position: relative;

    .floating-select {
      &::part(text) {
        @include pxToRem(margin-top, 10);
      }
    }

    .floating-unit {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;

      span {
        font-size: 14px;
        font-weight: normal;
        color: black;
      }
    }

    .floating-select,
    .floating-input {
      background-color: var(--ion-background-color);
      width: 100%;
      border: 1px solid var(--wohnsinn-border-color);
      border-radius: var(--wohnsinn-border-radius);
      overflow: hidden;
      outline: none;
      @include pxToRem(padding-top, 12);
      @include pxToRem(padding-left, 16);
      @include pxToRem(height, 44);

      &:focus {
        border-color: var(--ion-color-primary);
      }
    }

    .floating-label {
      z-index: 2;
      position: absolute;
      transition: all 0.1s linear;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      @include pxToRem(left, 16);
    }

    .floating-input:focus + .floating-label {
      span {
        color: var(--ion-color-primary);
      }
    }

    .floating-select + .floating-label.isInputFilled,
    .floating-select:focus + .floating-label,
    .floating-input + .floating-label.isInputFilled,
    .floating-input:focus + .floating-label {
      transform: translateY(calc(-50% - 12px));

      span {
        font-size: 10px !important;
      }
    }
  }
}
