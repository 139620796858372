@import "src/theme/mixins/utils";

.wrapper {
  border: 1px solid var(--wohnsinn-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-text-color-light);
  @include pxToRem(width, 35);
  @include pxToRem(height, 35);
  @include pxToRem(border-radius, 9999);

  &:hover {
    background-color: var(--ion-color-primary-tint);
  }

  &.isChecked {
    background-color: var(--ion-color-primary-tint);
  }
}
