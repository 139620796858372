@import "src/theme/mixins/landlord-landing";

.wrapper {
  @include landlord-landing-section-padding;

  .table {
    border-radius: var(--wohnsinn-border-radius);
    overflow: hidden;
    @include pxToRem(margin-top, 48);

    .row {
      display: flex;
      @include pxToRem(padding, 6);

      &:nth-child(even) {
        background-color: var(--ion-color-tertiary);
      }

      .label {
        flex: 4;

        @include for-min-size(md) {
          flex: 3;
        }
      }

      .compare {
        display: flex;
        flex: 2;

        @include for-min-size(md) {
          flex: 2;
        }

        .logo {
          img {
            margin: 0 auto;
          }
        }

        > * {
          flex: 1;
          text-align: center;

          @include for-max-size(sm) {
            @include pxToRem(max-width, 70);
            @include pxToRem(min-width, 70);
          }

          &.check {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.hideMobile {
            @include for-max-size(md) {
              display: none;
            }

          }
        }

      }
    }
  }
}
