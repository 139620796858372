@import '../../../theme/mixins/fonts';
@import '../../../theme/mixins/responsive';
@import '../../../theme/mixins/utils';

.chatHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 0;
  @include pxToRem(margin-top, 4);
  @include pxToRem(margin-bottom, 10);

  &.isSender {
    justify-content: flex-end;
  }
}

.chatMessage {
  .text {
    display: inline-block;
    overflow-wrap: anywhere;
    background-color: var(--ion-background-color);
    color: var(--ion-text-color);
    border-radius: 0 20px 20px;
    border-color: var(--wohnsinn-border-color);
    @include pxToRem(margin-bottom, 8);

    p span {
      font-weight: 100;
    }

    @include pxToRem(padding-top, 7);
    @include pxToRem(padding-bottom, 7);
    @include pxToRem(padding-right, 14);
    @include pxToRem(padding-left, 14);
  }

  &.chatMessageRight {
    .text {
      margin-left: 30px;
      margin-right: 0;
      float: right;
      background-color: var(--ion-color-tertiary);
      border-radius: 20px 0 20px 20px;
    }

    .chatHeader {
      margin-left: 30px;
      align-items: end;
    }

  }
}


