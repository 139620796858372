@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(margin-top, 8);
  @include pxToRem(margin-bottom, 8);
}

.uploadItem {
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-gray);
  background-color: var(--ion-color-light);
  overflow: hidden;
  @include pxToRem(margin-top, 8);

  .description {
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .uploadItemContent {
    display: flex;
    justify-content: space-between;
  }

  .buttonWrapper {
    display: flex;
    @include pxToRem(gap, 32);
    @include pxToRem(margin-right, 16);
  }
}
