@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(margin-top, -17);

  @media print {
    margin-top: -15px;
  }
}

.item {
  --padding-start: 0 !important;
  --background: var(--ion-background-color);

  cursor: pointer;

  &:hover {
    color: var(--ion-color-primary-shade);
  }

  .label {
    text-overflow: clip;
    white-space: normal;

    @media print {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
