@import "../../theme/mixins/fonts";
@import "../../theme/mixins/utils";

.wrapper {
  display: flex;
  position: relative;
}

.apartmentInformation {
  position: relative;
  max-width: 100%;
  width: 100%;

  @include for-min-size(md) {
    border-right: 1px solid var(--wohnsinn-border-color);
    height: calc(100vh - var(--desktop-menu-height));
    @include pxToRem(max-width, 500);

    overflow-y: scroll;
  }

  @include for-min-size(lg) {
    @include pxToRem(max-width, 500);
  }

  .apartment {
    /* Hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    background-color: white;
    @include pxToRem(padding-bottom, 100);

    @include for-min-size(md) {
      border-right: 1px solid var(--wohnsinn-border-color);
      padding-bottom: 0;
      @include pxToRem(width, 500);
    }
  }


}

.map {
  display: none;
  position: relative;
  width: calc(100% - 400px);

  @include for-min-size(md) {
    display: block;
  }

  .disabledTagWrapper {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.ratings {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  width: 100%;

  @include for-min-size(md) {
    @include pxToRem(width, 500);
  }

}
