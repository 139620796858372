@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.logoWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  @include pxToRem(margin-bottom, 24);

  .organizationImage {
    width: 100%;

    @include pxToRem(margin-bottom, 24);
    @include pxToRem(max-width, 200);
    @include pxToRem(max-height, 300);
  }
}
