@import "src/theme/mixins/landlord-landing";
@import "src/theme/mixins/utils";

.wrapper {
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include landlord-landing-section-padding;

  .content {
    display: flex;
    flex-direction: column;

    @include for-min-size(md) {
      flex-direction: row;
    }
  }

  .accordion {
    flex: 2;
  }

  .mockup {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);
    @include pxToRem(padding, 32);
  }
}
