@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

p.text {
  @include pxToRem(margin-bottom, 6);
  @include pxToRem(margin-top, 6);
}

.text {
  color: var(--ion-text-color);
  font-weight: 400;
  letter-spacing: .2pt;
  @include pxToRem(font-size, 16);
  @include pxToRem(line-height, 24);

  &.uppercase {
    text-transform: uppercase;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  // COLORS

  &.TEXT_COLOR_DARK {
    color: var(--ion-text-color);
  }

  &.TEXT_COLOR_LIGHT {
    color: var(--ion-text-color-light);
  }

  &.TEXT_COLOR_ACCENT {
    color: var(--ion-text-color-accent);
  }

  &.TEXT_COLOR_PRIMARY {
    color: var(--ion-color-primary);
  }

  &.TEXT_COLOR_DANGER {
    color: var(--ion-color-danger) !important;
  }

  // TYPES
  &.TEXT_TYPE_BODY {
    @include pxToRem(font-size, 16);
    @include pxToRem(line-height, 24);

    &.TEXT_VARIANT_SMALL {
      @include pxToRem(font-size, 14);
      @include pxToRem(line-height, 20);
    }
  }

  &.TEXT_TYPE_CAPTION {
    @include pxToRem(font-size, 12);
    @include pxToRem(line-height, 14);

    &.TEXT_VARIANT_SMALL {
      @include pxToRem(font-size, 11);
      @include pxToRem(line-height, 13);
    }
  }

  &.TEXT_TYPE_FOOTER {
    @include pxToRem(font-size, 10);
    @include pxToRem(line-height, 12);
  }

  // WEIGHTS
  &.TEXT_WEIGHT_BOLD {
    font-weight: var(--font-weight--bold);
  }

  &.TEXT_WEIGHT_REGULAR {
    font-weight: var(--font-weight--regular);
  }

  &.TEXT_WEIGHT_LIGHT {
    font-weight: var(--font-weight--light);
  }

  &.overFlowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  @include for-max-size(md) {
    &.mobile-left {
      text-align: left;
    }

    &.mobile-center {
      text-align: center;
    }

    &.mobile-right {
      text-align: right;
    }
  }
}
