@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.silhouette {
  background-color: var(--wohnsinn-border-color);
  display: flex;
  justify-content: center;
  align-items: center;

  @include pxToRem(border-radius, 9999);
  @include pxToRem(width, 100);
  @include pxToRem(height, 100);

}

.avatar {
  background-size: cover;
  @include pxToRem(border-radius, 9999);

  &.border {
    @include boxShadow;
  }

  &.xs {
    @include pxToRem(width, 30);
    @include pxToRem(height, 30);

    svg {
      @include pxToRem(height, 10);
    }
  }

  &.sm {
    @include pxToRem(width, 34);
    @include pxToRem(height, 34);

    svg {
      @include pxToRem(height, 14);
    }
  }

  &.md {
    @include pxToRem(width, 40);
    @include pxToRem(height, 40);

    svg {
      @include pxToRem(height, 20);
    }
  }

  &.lg {
    @include pxToRem(width, 60);
    @include pxToRem(height, 60);

    svg {
      @include pxToRem(height, 30);
    }
  }

  &.xxl {
    @include pxToRem(width, 120);
    @include pxToRem(height, 120);

    @include for-min-size(md) {
      @include pxToRem(width, 150);
      @include pxToRem(height, 150);
    }

    svg {
      @include pxToRem(height, 75);
    }
  }
}


