@import "src/theme/mixins/utils";

.wrapper {
  .subtitle {
    margin-left: auto;
    margin-right: auto;
    @include pxToRem(margin-top, 16);
    @include pxToRem(margin-bottom, 16);
    @include pxToRem(max-width, 550);
  }
}
