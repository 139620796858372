@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.enterPhoneNumber {
  text-align: center;
}

.subHeadline {
  text-align: center;
  @include text-small("bold");
}

.description {
  margin-left: 0;
  margin-right: 0;
  @include text-tiny("light");
  @include pxToRem(margin-top, 8);
  @include pxToRem(margin-bottom, 8);
}

.input {
  @include pxToRem(margin-top, 16);
}

.resendVerification {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: var(--ion-color-primary);
  @include pxToRem(margin-top, 16);
  @include pxToRem(margin-bottom, 16);
  @include pxToRem(padding, 16);
  @include pxToRem(padding, 16);
}
