@import "src/theme/mixins/utils";

.pagination {
  display: flex;
  width: 100%;
  @include pxToRem(gap, 12);
  @include pxToRem(padding-left, 16);
  @include pxToRem(margin-bottom, 60);

  .paginationItem {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--wohnsinn-border-color);
    @include pxToRem(width, 35);
    @include pxToRem(height, 35);
    @include pxToRem(border-radius, 9999);

    &:hover,
    &.active {
      border-color: var(--ion-color-primary);
      background-color: var(--ion-color-primary);
    }
  }
}
