@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/landlord-landing";

.wrapper {
  background-color: var(--ion-color-tertiary);
  @include full-width-background(tertiary);

  .content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    @include pxToRem(gap, 30);
    @include landlord-landing-section-padding;

    @include for-min-size(md) {
      flex-direction: row;
    }

    .text {
      .buttonWrapper {
        display: flex;
        flex-direction: column;
        @include pxToRem(gap, 16);
        @include pxToRem(margin-top, 32);

        @include for-min-size(md) {
          flex-direction: row;
          @include pxToRem(gap, 24);
        }
      }
    }

    .image {
      max-width: 200px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include for-min-size(md) {
        max-width: unset;
      }

      .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include pxToRem(max-width, 300);
      }
    }
  }
}
