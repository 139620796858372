@import "src/theme/mixins/utils";

.actionBtn {
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: 9999px;
  background-color: var(--ion-background-color);
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    color: var(--ion-color-primary);
  }


  .notification {
    position: absolute;
    top: -2px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    background-color: var(--ion-color-danger-shade);
  }

  &:hover {
    @include buttonAnimation;
  }
}
