@import "src/theme/mixins/utils";

.wrapper {
  .content {
    border-radius: 9999px;
    position: relative;
    @include pxToRem(width, 120);
    @include pxToRem(height, 120);
    @include boxShadow;
  }
}

.tenantInformationWrapper {
  display: flex;
  width: 100%;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
}


.imageWrapper {
  margin-bottom: 1rem;

  img {
    @include pxToRem(border-radius, 9999);
    @include pxToRem(margin-bottom, 8);
    @include pxToRem(width, 120);
  }
}

.silhouette {
  background-color: var(--wohnsinn-border-color);
  display: flex;
  justify-content: center;
  align-items: center;

  @include pxToRem(border-radius, 9999);
  @include pxToRem(width, 120);
  @include pxToRem(height, 120);

  svg {
    @include pxToRem(max-width, 50);
  }
}

.delete,
.camera {
  position: absolute;
  background-color: var(--ion-color-primary-contrast);
  border: 1px solid var(--wohnsinn-border-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @include pxToRem(border-radius, 9999);
  @include pxToRem(width, 30);
  @include pxToRem(height, 30);
  @include pxToRem(left, -4);
  @include pxToRem(bottom, 15);

  svg {
    @include pxToRem(max-width, 15);
  }
}

.delete {
  background-color: var(--ion-color-danger);
  left: unset;

  @include pxToRem(border-radius, 9999);
  @include pxToRem(right, -4);
}
