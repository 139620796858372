@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include for-min-size(lg) {
    flex-direction: row;
    justify-content: space-between;
  }

  .column {
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;

    .badgeWrapper {
      display: inline-flex;
      @include pxToRem(gap, 10);
    }
  }

  .submitButton {
    display: none;

    @include for-min-size(lg) {
      display: flex;
      justify-content: flex-end;
      @include pxToRem(gap, 12);
    }
  }
}
