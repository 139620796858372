.dropdown {
  width:100%;
}

.dropdownWrapper {
  width:100%;
  display: flex;

  .dateFieldWrapper {
    position: relative;
    width: 100%;
    border-radius: var(--wohnsinn-border-radius);
    border: 1px solid var(--wohnsinn-border-color);
    padding: 16px 16px 2px;
    text-align: left;

    .dateFieldWrapperLabel {
      position: absolute;
      top: 3px;
    }
  }
}
