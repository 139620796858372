@import "src/theme/mixins/utils";

.copyButtonWrapper {
  display: flex;
  @include pxToRem(gap, 10);

  .button {
    &:hover {
      color: var(--ion-color-primary);

      svg {
        color: var(--ion-color-primary);
      }
    }
  }
}
