@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.apartment {
  position: absolute;
  bottom: 0;
  top: 0;
  overflow-y: scroll;

  /* Hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }


  width: 100%;
  background-color: white;
  @include pxToRem(padding-bottom, 100);

  @include for-min-size(md) {
    border-right: 1px solid var(--wohnsinn-border-color);
    padding-bottom: 0;
    @include pxToRem(width, 500);
  }
}
