@import "src/theme/mixins/utils";

.wrapper {
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding, 32);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .organization {
    @include pxToRem(margin-bottom, 24);
  }

  .landlord {
    display: flex;
    align-items: center;
    @include pxToRem(margin-top, 24);
    @include pxToRem(gap, 10);
  }
}