@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.schufaButtonWrapper {
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 16);

  @include for-min-size(lg) {
    flex-wrap: wrap;
    justify-content: center;
  }

}
