@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.section {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);

  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-bottom, 16);

  &.reducePadding {
    @include pxToRem(padding-top, 10);
    @include pxToRem(padding-bottom, 10);
  }

  @include for-min-size(lg) {
    @include pxToRem(padding-top, 32);
    @include pxToRem(padding-bottom, 32);
  }

  &.white {
    --bg-color: var(--ion-background-color);

    background-color: var(--bg-color);
  }

  &.grey {
    --bg-color: var(--ion-color-light);

    background-color: var(--bg-color);
  }

}
