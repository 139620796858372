@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wohnsinn-forms-grid {
  --ion-grid-padding: 0;
}

.wohnsinn-form-section {
  @include for-max-size(md) {
    ion-col {
      border-bottom: 1px solid var(--wohnsinn-border-color);
      padding-bottom: 20px;
      margin-bottom: 20px;

      & > div:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .wrapper {
        margin-top: 20px;
      }
    }
  }

}

.form-field {
  width: 100%;
  @include pxToRem(margin-bottom, 16);
}

input:disabled {
  background-color: var(--ion-color-light-shade);
}
